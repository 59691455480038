.card-container {
    background-color: #3636a7;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 500px;
    margin: 10px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    display: flex;
    background-color: #2a91d6;
    margin: 10px;
    padding: 10px;
    min-height: 160px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text {
    font-size: large;
}

.description {
    font-size: small;
}

.orange-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d66f2a;
    margin: 4px;
    border-radius: 5px;
    min-height: 40px;
}

.light-blue-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightskyblue;
    margin: 10px;
    min-height: 40px;
    border-radius: 5px;
}
