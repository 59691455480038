.App {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}



.App > div {
  flex: 1;
}

.home {
  background: #3636a7;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}

.container {
  display: flex;
  justify-content: center;
}

.idea {
  display: flex;
  flex-direction: column;
  background-color: #d66f2a;
  padding: 10px;
  border-radius: 5px;
  min-height: 80px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.p {
  margin: 2px;
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  background: #223773;
}

.navigation a {
  text-decoration: none;
  display: block;
  padding: 1em;
  margin: 2px;
  color: white;
  background: #3456b5
}

.active-link {
  background: #587bde !important;
}

.pointer {
  cursor: pointer;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 30%;
  align-content: center;
  align-self: flex-end;
}

.checkbox-with-label {
  display: flex;
  flex: none;
  background: #e26817;
  margin: 5px 1px 5px 1px;
  padding: 7px 2px 7px 2px;
  border-radius: 5px;
}

.deck-container {
  background-color: #3636a7;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 500px;
  margin: 10px;
}

.deck-item1 {
  flex-basis: 15%;
}

.deck-item2 {
  flex-basis: 30%;
}
